<template>
    <v-card tile class="before__no-background" color="transparent" flat>
        <div class="py-16px">
            <v-row align-md="center" class="ma-n8px">
                <v-col v-if="$slots['select']" cols="12" md="auto" class="px-8px pt-8px pb-0">
                    <slot name="select" />
                </v-col>
                <v-col cols="auto" class="pa-8px">
                    <v-card :disabled="isSoldOut" tile flat style="overflow: hidden">
                        <router-link v-bind="{ to }" v-if="isLink">
                            <v-img v-bind="{ src }" width="78" height="78">
                                <template #placeholder>
                                    <v-overlay absolute opacity="0.1"> <v-icon>mdi-image-broken-variant</v-icon> </v-overlay>
                                </template>
                            </v-img>
                        </router-link>
                        <template v-else>
                            <v-img v-bind="{ src }" width="78" height="78">
                                <template #placeholder>
                                    <v-overlay absolute opacity="0.1"> <v-icon>mdi-image-broken-variant</v-icon> </v-overlay>
                                </template>
                            </v-img>
                        </template>
                    </v-card>
                </v-col>
                <v-col class="pa-8px">
                    <v-card color="transparent" :disabled="isSoldOut" flat>
                        <div v-if="isSoldOut" class="mb-4px mb-md-12px">
                            <v-chip x-small color="grey">품절</v-chip>
                        </div>
                        <!-- <div class="txt txt--sm txt--dark font-weight-bold mb-4px" v-if="product?.flags?.length">{{ product.flags.map((text) => `[${text}]`).join(" ") }}</div> -->
                        <div class="txt txt--sm txt--dark mb-12px">
                            <router-link v-bind="{ to }" v-if="isLink">
                                <span v-if="product?.brand?.name">[{{ product?.brand?.name || "" }}]</span> {{ product?.name }}
                            </router-link>
                            <template v-else>
                                <span v-if="product?.brand?.name">[{{ product?.brand?.name || "" }}]</span> {{ product?.name }}
                            </template>
                        </div>
                        <!-- <div v-if="product?.discountRate" class="font-size-14 text-decoration-line-through grey--text text--lighten-1">{{ product?.price?.format?.() }}원</div>
                        <div class="d-flex align-center font-size-18 font-size-md-20 font-size-lg-24">
                            <strong v-if="product?.discountRate" class="secondary--text mr-8px"> {{ product?.discountRate }}% </strong>
                            <strong class="d-inline-flex align-center">{{ product?.salePrice?.format?.() }}<small class="font-size-14 font-weight-regular pl-2px">원</small></strong>
                        </div> -->
                    </v-card>

                    <slot name="content" />
                </v-col>
                <v-col v-if="$slots['actions']" cols="12" md="auto" class="pa-8px">
                    <div class="w-md-120px">
                        <slot name="actions" />
                    </div>
                </v-col>
            </v-row>
        </div>
        <v-divider v-if="!hideDivider" />
    </v-card>
</template>

<script>
import { btn_txt } from "@/assets/variables";
import LikeBtn from "../../like/like-btn.vue";

export default {
    components: {
        LikeBtn,
    },
    props: {
        product: { type: Object, default: () => ({}) },
        carts: { type: Array, default: () => [] },

        isLink: { type: Boolean, default: false },
        isSoldOut: { type: Boolean, default: false },
        hideDivider: { type: Boolean, default: false },
    },
    data: () => ({
        btn_txt,
    }),
    computed: {
        src() {
            let { thumb } = this.product || {};
            if (thumb) return thumb?.url;
        },
        to() {
            if (!this.isLink) return;

            let _id = this.product?._id;
            if (this.product?.variation?.enabled) {
                _id = this.product?.variation?.head?._id || _id;
            }

            return `/shop/products/${_id}`;
        },
    },
};
</script>

<style lang="scss" scoped>
.before__no-background:before {
    background: initial !important;
}
</style>
