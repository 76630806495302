var render = function render(){
  var _vm$product, _vm$product$brand, _vm$product2, _vm$product2$brand, _vm$product3, _vm$product4, _vm$product4$brand, _vm$product5, _vm$product5$brand, _vm$product6;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', {
    staticClass: "before__no-background",
    attrs: {
      "tile": "",
      "color": "transparent",
      "flat": ""
    }
  }, [_c('div', {
    staticClass: "py-16px"
  }, [_c('v-row', {
    staticClass: "ma-n8px",
    attrs: {
      "align-md": "center"
    }
  }, [_vm.$slots['select'] ? _c('v-col', {
    staticClass: "px-8px pt-8px pb-0",
    attrs: {
      "cols": "12",
      "md": "auto"
    }
  }, [_vm._t("select")], 2) : _vm._e(), _c('v-col', {
    staticClass: "pa-8px",
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-card', {
    staticStyle: {
      "overflow": "hidden"
    },
    attrs: {
      "disabled": _vm.isSoldOut,
      "tile": "",
      "flat": ""
    }
  }, [_vm.isLink ? _c('router-link', _vm._b({}, 'router-link', {
    to: _vm.to
  }, false), [_c('v-img', _vm._b({
    attrs: {
      "width": "78",
      "height": "78"
    },
    scopedSlots: _vm._u([{
      key: "placeholder",
      fn: function () {
        return [_c('v-overlay', {
          attrs: {
            "absolute": "",
            "opacity": "0.1"
          }
        }, [_c('v-icon', [_vm._v("mdi-image-broken-variant")])], 1)];
      },
      proxy: true
    }], null, false, 2495918059)
  }, 'v-img', {
    src: _vm.src
  }, false))], 1) : [_c('v-img', _vm._b({
    attrs: {
      "width": "78",
      "height": "78"
    },
    scopedSlots: _vm._u([{
      key: "placeholder",
      fn: function () {
        return [_c('v-overlay', {
          attrs: {
            "absolute": "",
            "opacity": "0.1"
          }
        }, [_c('v-icon', [_vm._v("mdi-image-broken-variant")])], 1)];
      },
      proxy: true
    }])
  }, 'v-img', {
    src: _vm.src
  }, false))]], 2)], 1), _c('v-col', {
    staticClass: "pa-8px"
  }, [_c('v-card', {
    attrs: {
      "color": "transparent",
      "disabled": _vm.isSoldOut,
      "flat": ""
    }
  }, [_vm.isSoldOut ? _c('div', {
    staticClass: "mb-4px mb-md-12px"
  }, [_c('v-chip', {
    attrs: {
      "x-small": "",
      "color": "grey"
    }
  }, [_vm._v("품절")])], 1) : _vm._e(), _c('div', {
    staticClass: "txt txt--sm txt--dark mb-12px"
  }, [_vm.isLink ? _c('router-link', _vm._b({}, 'router-link', {
    to: _vm.to
  }, false), [(_vm$product = _vm.product) !== null && _vm$product !== void 0 && (_vm$product$brand = _vm$product.brand) !== null && _vm$product$brand !== void 0 && _vm$product$brand.name ? _c('span', [_vm._v("[" + _vm._s(((_vm$product2 = _vm.product) === null || _vm$product2 === void 0 ? void 0 : (_vm$product2$brand = _vm$product2.brand) === null || _vm$product2$brand === void 0 ? void 0 : _vm$product2$brand.name) || "") + "]")]) : _vm._e(), _vm._v(" " + _vm._s((_vm$product3 = _vm.product) === null || _vm$product3 === void 0 ? void 0 : _vm$product3.name) + " ")]) : [(_vm$product4 = _vm.product) !== null && _vm$product4 !== void 0 && (_vm$product4$brand = _vm$product4.brand) !== null && _vm$product4$brand !== void 0 && _vm$product4$brand.name ? _c('span', [_vm._v("[" + _vm._s(((_vm$product5 = _vm.product) === null || _vm$product5 === void 0 ? void 0 : (_vm$product5$brand = _vm$product5.brand) === null || _vm$product5$brand === void 0 ? void 0 : _vm$product5$brand.name) || "") + "]")]) : _vm._e(), _vm._v(" " + _vm._s((_vm$product6 = _vm.product) === null || _vm$product6 === void 0 ? void 0 : _vm$product6.name) + " ")]], 2)]), _vm._t("content")], 2), _vm.$slots['actions'] ? _c('v-col', {
    staticClass: "pa-8px",
    attrs: {
      "cols": "12",
      "md": "auto"
    }
  }, [_c('div', {
    staticClass: "w-md-120px"
  }, [_vm._t("actions")], 2)]) : _vm._e()], 1)], 1), !_vm.hideDivider ? _c('v-divider') : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }